@import "node_modules/bootstrap/scss/forms";

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.form-group.required .col-form-label {
  color: $brand-classic;
}

.form-horizontal.form-no-row {
    .form-group {
        margin-left: 0;
        margin-right: 0;
    }
}

.form-group .form-control,
.form-control {
    box-shadow: none;

    @include form-control-focus;
    &:focus {
        border-color: $brand-classic;
    }
    &.border-light {
        border-color: $brand-lightblue;
    }
}


.input-group {
  .form-control {
    &:focus {
      box-shadow: none;
      border-color: transparent;
    }
  }
}

.form-flat {
    .form-group {
        .form-control {
            border-color: $brand-lightblue;
        }

        &.is-invalid {
            .form-control {
                border-color: $danger;
            }
        }
    }
}

.has-error {
  color: $danger
}


/*
.form-group.required {
    .form-control {
//      background-size: 0 1px, 100% 1px;
      background-image: linear-gradient($primary, $primary), linear-gradient($brand-classic, $brand-classic);
    }

    .col-form-label {
        color: $brand-classic;
    }
}
*/
.form-group.form-control-lg.required label.col-form-label,
.form-group.required label.col-form-label
 {
    color: $brand-classic;
}

