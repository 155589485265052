.ss-result {

        background-color: $white; // $brand-lightblue;
        @include shadow-z-1();
        @include transition(all 0.33s ease);
        margin-bottom: 20px;
        padding: 0 ($grid-gutter-width / 2);

        > .row {
            padding: ($grid-gutter-width / 2) 0;
        }

        &:hover {
            @include shadow-z-2();
        }

//    margin-bottom: 2em;
//    border-bottom: 1px solid $brand-lightblue;
}
.ss-result__title {
  @include font-size($h2-font-size);
}
.ss-result__badge {
    font-size: .7em;
    padding: .2em .5em;
    border-radius: 4px;
    margin-left: .75em;
    background: #eee;
    display: inline-block;
}
.ss-result__text {
    margin-bottom: .5em;
}
.ss-result__url {
    @extend .btn-like;
}
