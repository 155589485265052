//include bootstrap


@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "./mixins";
@import "node_modules/bootstrap/scss/mixins";
@import "./bootstrap-utilities";


@import "./variables";
@import "./_mdb_variables";
@import "./bootstrap-components";
@import "./bootstrap-css";
@import "./type";


@import "./fonts";
// Font awesome
@import "node_modules/@fortawesome/fontawesome-free/css/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/css/brands";
@import "node_modules/@fortawesome/fontawesome-free/css/solid";
@import "node_modules/@fortawesome/fontawesome-free/css/svg-with-js";

@import "./custom";
@import "./responsive-utilities";


@import "iefix";

html, body {
  min-height: 100%;
}
body {
  position: relative;
}

@import "./bootstrap-addons";

@import "./slick";
@import "simple-scrollbar";
@import "node_modules/animate.css/animate";

@import "./helper";

@import "../../../../modules/system/assets/css/framework.extras";
