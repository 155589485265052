//
// Cards
// --------------------------------------------------


// Base class
.card {
    position: relative;
//    height: 275px;
    margin-bottom: $grid-gutter-width;
    border-radius: 0;
    border-color: $brand-lightblue;
    @include transition(all 0.33s ease);

    &:before {
        content: "";
        display: block;
        padding-top: 50%;
    }

    &:hover {
        @include shadow-z-2();

        .card-icon {
            color: $white;
        }
    }

    &.disabled {
        cursor: not-allowed;
        @include opacity(.67);

        &:hover {
            @include box-shadow(none);
        }
    }

    &.lazyload {
      background-image: none !important;
    }

}

.card-default:before {
  padding: 0;
  display: none;
}

.card-1h-lg:before {
    padding-top: 275px;

    @include media-breakpoint-down(sm) {
        padding-top: 67%;
    }
}
.card-1h:before {
    padding-top: 275px;

    @include media-breakpoint-down(sm) {
        padding-top: 50%;
    }
}
.card-2h:before {
    padding-top: 580px;

    @include media-breakpoint-down(sm) {
        padding-top: 67%;
    }
}
.card-square:before {
    padding-top: 100%;
}
.card-23:before {
    padding-top: 67%;
}


.card-icon {
    position: absolute;
    top: 10px;
    left: 20px;
    color: rgba(255,255,255,.8);
}

.card-service {
  .card-icon {
    top: 10px;
    left: auto;
    right: 20px;
  }
}


.card-content {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    color: $white;
    @include gradient-y($start-color: transparent, $end-color: rgba($primary, .9));

    @media (max-width: 480px) {
        height: 60%;
        bottom: 0;
        text-shadow: 1px 1px 4px $primary;
        @include hyphens(auto);

        .fa-angle-right {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        text-shadow: 1px 1px 4px rgba($primary, 0.67);
    }

    .card-headline {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
        color: $white;

        @media (min-width: 600px) and (max-width: $screen-sm-max) {
            font-size: 16px;
        }

        @media (min-width: $screen-xs) and (max-width: 599px) {
            font-size: 12px;
        }
    }

    .card-inner {
        position: absolute;
        bottom: 0;
        padding: 0 20px;

        p {
            margin-top: -($line-height-computed / 2);
        }
    }
}

.card-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

// Base class
.image-result {
    position: relative;
//    height: 275px;
    width: 100%;
    margin: 0 15px 0 0;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media-breakpoint-down(md) {
        max-width: 50%;
        width: auto;
    }

    &:before {
        content: "";
        display: block;
        padding-top: 200px;

        @include media-breakpoint-down(sm) {
            padding-top: 150px;
        }
    }
}

.card-hada {
  background-color: transparent;
  border-color: $primary;
}

.card-light {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: $brand-lightblue;
  border: 1px solid #d7ecfd;
  h3,
  .h3 {
    margin-top: ($line-height-computed / 2);
  }
}


.card-yoga {
    background-image: url('../img/cards/relax.CACHEBUSTER.jpg');
}
.card-akupunktur,
.card-alternative-therapien,
.card-bei-chronischen-kopfschmerzen-hilft-akupunktur,
.card-akupunktur-als-heilmethode,
.card-migraene-mit-akupunktur-lindern,
.card-alternativeheilmethodenakupunktur,
.card-migraeneprophylaxeakupunktur {
    background-image: url('../img/cards/89033293.CACHEBUSTER.jpg');
    background-position: right center;
}
.card-alarmsignale,
.card-wann-zum-arzt,
.card-diagnose,
.card-wann-zum-arzt-mit-kopfschmerzen,
.card-wannzumarzt {
    background-image: url('../img/cards/000078183441.CACHEBUSTER.jpg');

}

.card-alternativ,
.card-alternative-heilmethoden,
.card-kopfschmerzen-alternative-heilmethoden-im-ueberblick,
.card-alternativeheilmethoden {
  // background-image: url('../img/cards/89033293.CACHEBUSTER.jpg');
  background-image: url('../img/cards/alternativ.CACHEBUSTER.jpg');
}

.card-expert,
.card-experten,
.card-kopfschmerzen-experten-verraten-ihre-tipps {
    background-image: url('../img/cards/91507759.CACHEBUSTER.jpg');
}
.card-kopfschmerzfrei,
.card-kopfschmerzen-tipps-fuer-betroffene,
.card-tipps-hilfe,
.card-tippshilfe {
    background-image: url('../img/cards/60847185.CACHEBUSTER.jpg');
}
.card-wirkstoffuebersicht,
.card-wirkstoffe {
  background-image: url('../img/cards/wirkstoffe.CACHEBUSTER.jpg');
  background-position: center bottom;
}

.card-kopfschmerzfallen-im-herbst {
  background-image: url('../img/cards/wirkstoffe.CACHEBUSTER.jpg');
}

.card-service,
.card-services,
.card-der-kopfschmerzenservice-fuer-patienten {
    background-image: url('../img/cards/service.CACHEBUSTER.jpg');
    background-position: right bottom;
}

.card-spannungskopfschmerz,
.card-spannungskopfschmerzen,
.card-spannungskopfschmerzen-symptome-verlauf-behandlung {
    background-image: url('../img/cards/103818876.CACHEBUSTER.jpg');
    background-position: right top;
}

.card-clusterkopfschmerz,
.card-cluster-kopfschmerzen,
.card-cluster-kopfschmerzen-wie-eine-nadel-im-auge,
.card-migraene,
.card-clusterkopfschmerzen {
    background-image: url('../img/cards/000022673204.CACHEBUSTER.jpg');
    background-position: right top;
}
.card-kopfschmerzweitere {
    background-image: url('../img/cards/103818876.CACHEBUSTER.jpg');
    background-position: right top;
}
.card-kopfschmerzursachen,
.card-ursachen-fuer-kopfschmerzen {
    background-image: url('../img/cards/123113293.CACHEBUSTER.jpg');
}
.card-kopfschmerzirrtuemer,
.card-die-groessten-kopfschmerzirrtuemer,
.card-die-groessten-kopfschmerz-irrtuemer {
    background-image: url('../img/cards/83359951.CACHEBUSTER.jpg');
    background-position: right top;
}
.card-wissenswertes,
.card-kopfschmerzen-wissenswertes-ueber-das-volksleiden {
    background-image: url('../img/cards/92506512.CACHEBUSTER.jpg');
}
.card-hinterkopf,
.card-kopfschmerzen-am-hinterkopf,
.card-kopfschmerzen-am-hinterkopf-das-steckt-dahinter,
.card-weiterekopfschmerzartenhinterkopf {
    background-image: url('../img/cards/hinterkopf.CACHEBUSTER.jpg');
}
.card-stirn,
.card-kopfschmerzen-an-der-stirn,
.card-kopfschmerzen-an-stirn-und-schlaefe,
.card-kopfschmerzen-an-der-stirn-das-steckt-dahinter,
.card-weiterekopfschmerzartenstirn {
    background-image: url('../img/cards/stirn.CACHEBUSTER.jpg');
}
.card-schwanger,
.card-kopfschmerzen-in-der-schwangerschaft,
.card-kopfschmerzen-in-der-schwangerschaft-ursachen-und-behandlung,
.card-weiterekopfschmerzartenschwanger {
    background-image: url('../img/cards/000046117308.CACHEBUSTER.jpg');
    background-position: center top;
}
.card-kurios,
.card-ungewoehnliche-kopfschmerzarten,
.card-sieben-ungewoehnliche-kopfschmerzarten,
.card-weiterekopfschmerzartenkurios {
    background-image: url('../img/cards/000069188243.CACHEBUSTER.jpg');
}

.card-weitere-kopfschmerzarten,
.card-weitere-kopfschmerzarten-richtig-benennen,
.card-weiterekopfschmerzarten {
    background-image: url('../img/cards/94919167.CACHEBUSTER.jpg');
}

.card-infektion,
.card-kopfschmerzen-durch-erkaeltung,
.card-alles-wissenswerte-zu-kopfschmerzen-durch-erkaeltung,
.card-weiterekopfschmerzartenerkaeltung {
    background-image: url('../img/cards/000019701923.CACHEBUSTER.jpg');
}

.card-ernaehrung,
.card-kopfschmerzen-durch-ernaehrung,
.card-wenn-kopfschmerzen-durch-ernaehrung-bedingt-sind,
.card-weiterekopfschmerzartenernaehrung {
    background-image: url('../img/cards/000083145271.CACHEBUSTER.jpg');
}

.card-fluessigkeitsmangel,
.card-kopfschmerzen-durch-fluessigkeitsmangel,
.card-durstig-statt-krank-kopfschmerzen-durch-fluessigkeitsmangel,
.card-kopfschmerzursachenfluessigkeitsmangel {
    background-image: url('../img/cards/000075069663.CACHEBUSTER.jpg');
    background-position: right top;
}
.card-stress,
.card-kopfschmerzen-durch-stress,
.card-kopfschmerzursachenstress {
    background-image: url('../img/cards/000044937048.CACHEBUSTER.jpg');
}
.card-wetter,
.card-wetterbedingten-kopfschmerzen-vorbeugen,
.card-ursachen-fuer-kopfschmerzen-im-ueberblick,
.card-kopfschmerzursachenwetter {
    background-image: url('../img/cards/wetter.CACHEBUSTER.jpg');
}

.card-oder,
.card-unterschied-kopfschmerzen-und-migraene,
.card-der-unterschied-zwischen-kopfschmerzen-und-migraene,
.card-wichtige-unterschiede-zwischen-kopfschmerzen-und-migraene,
.card-unterschiedkopfschmerzenmigraene,
.card-kopfschmerzenodermigraene {
    background-image: url('../img/cards/oder.CACHEBUSTER.jpg');
}
.card-migraeneprophylaxe,
.card-migraeneprophylaxe-diese-vorsorgemoeglichkeiten-gibt-es {
    background-image: url('../img/cards/90452380.CACHEBUSTER.jpg');
}
.card-migaeneinfo,
.card-was-ist-migraene,
.card-was-ist-migraene-ursachen-ausloeser-und-symptome,
.card-migraene-zahlen-und-fakten-ueber-die-haeufige-kopfschmerzart,
.card-wasistmigraene {
    background-image: url('../img/cards/migraene.CACHEBUSTER.jpg');
}
.card-migaeneverlauf,
.card-migraeneverlauf,
.card-der-verlauf-von-migraene-5-typische-phasen {
    background-image: url('../img/cards/verlauf.CACHEBUSTER.jpg');
    background-position: center bottom;
}

.card-triggerfaktoren,
.card-kontrolle-von-triggerfaktoren,
.card-migraene-vorbeugen-durch-die-kontrolle-von-triggern,
.card-migraeneprophylaxe-diese-vorsorgemoeglichkeiten-gibt-es,
.card-migraeneprophylaxetriggerfaktoren {
    background-image: url('../img/cards/stop.CACHEBUSTER.jpg');
}

.card-trigger,
.card-kopfschmerzen-trigger-besser-nicht-meiden,
.card-umgang-mit-triggerfaktoren,
.card-kopfschmerztrigger,
.card-newstrigger {
    background-image: url('../img/cards/trigger.CACHEBUSTER.jpg');
}
.card-trigger-large {
    background-image: url('../img/cards/kopfschmerzen-trigger-large.CACHEBUSTER.jpg');
}

.card-sport,
.card-migraene-mit-sport-lindern-so-gehts,
.card-migraeneprophylaxesport {
    background-image: url('../img/cards/sport.CACHEBUSTER.jpg');
}
.card-entspannungsmethoden,
.card-warum-bei-migraene-entspannung-wichtig-ist,
.card-migraeneprophylaxeentspannungsmethoden {
    background-image: url('../img/cards/relax.CACHEBUSTER.jpg');
    background-position: right top;
}
.card-biofeedback,
.card-biofeedbacktherapie,
.card-migraene-mit-biofeedbacktherapie-lindern,
.card-migraeneprophylaxebiofeedback {
    background-image: url('../img/cards/000085265649.CACHEBUSTER.jpg');
}
.card-stressbewaeltigung,
.card-stressbewaeltigungstraining,
.card-migraene-mit-stressbewaeltigungstraining-behandeln,
.card-migraeneprophylaxestressbewaeltigung {
    background-image: url('../img/cards/stress.CACHEBUSTER.jpg');
}

.card-kopfschmerztest,
.card-der-schnelle-kopfschmerztest-die-6-wichtigsten-fragen,
.card-ein-test-kann-bei-kopfschmerzen-klarheit-bringen,
.card-serviceskopfschmerztest {
    background-image: url('../img/cards/schmerztest.CACHEBUSTER.jpg');
}
.card-kopfschmerztagebuch,
.card-kopfschmerzen-mit-dem-kopfschmerztagebuch-dokumentieren,
.card-ein-kopfschmerztagebuch-hilft-bei-der-diagnose,
.card-kopfschmerztagebuch-zum-download,
.card-serviceskopfschmerztagebuch {
    background-image: url('../img/cards/100074059.CACHEBUSTER.jpg');
}

.card-glossar,
.card-die-wichtigsten-begriffe-zu-kopfschmerz-migraene-im-glossar,
.card-nutzungsbedingungen {
    background-image: url('../img/cards/icon.CACHEBUSTER.jpg');
    background-size: contain;
}

.card-news,
.card-der-kopfschmerzennewsticker,
.card-news-tipps {
    background-image: url('../img/cards/news.CACHEBUSTER.jpg');
    background-position: center bottom;
}

.card-diagnose-kopfschmerz,
.card-kopfschmerzen-mit-mrt-eeg-oder-ct-diagnostizieren,
.card-was-macht-der-arzt-waehrend-der-kopfschmerzdiagnose,
.card-eeg,
.card-mrt-eeg-oder-ct,
.card-was-macht-der-arzt,
.card-mrt-eeg-und-ct,
.card-was-bei-kopfschmerzen-fuer-die-diagnose-wichtig-ist,
.card-wasmachtderarzt,
.card-eegmrt {
    background-image: url('../img/cards/103729620.CACHEBUSTER.jpg');
}

.card-vorbeugung,
.card-vorbeugen,
.card-kopfschmerzen-vorbeugen-bevor-sie-sich-breit-machen,
.card-kopfschmerzen-vorbeugen,
.card-kopfschmerzenvorbeugen {
    background-image: url('../img/cards/vorbeugen.CACHEBUSTER.jpg');
}
.card-paracetamol,
.card-kopfschmerzen-mit-paracetamol-ibuprofen-und-ass-lindern,
.card-paracetamol-ibuprofen-und-as,
.card-paracetamol-ass-und-ibuprofen,
.card-diese-mittel-gegen-kopfschmerzen-gibt-es,
.card-wirkstoffeparacetamol {
    background-image: url('../img/cards/klassiker.CACHEBUSTER.jpg');
}
.card-koffein,
.card-bei-kopfschmerzen-kann-der-wirkstoff-coffein-helfen,
.card-wirkstoffekoffein {
    background-image: url('../img/cards/000023280396.CACHEBUSTER.jpg');
}
.card-coffein,
.card-kopfschmerzen-und-koffein,
.card-wissenswertes-koffein,
.card-bei-kopfschmerzen-hilft-koffein,
.card-wissenswerteskoffein {
    background-image: url('../img/cards/coffein.CACHEBUSTER.jpg');
}
.card-weitere-wirkstoffe {
    background-image: url('../img/cards/103818876.CACHEBUSTER.jpg');
}
.card-kombinationspraeparate,
.card-kopfschmerzen-mit-kombinationspraeparaten-lindern,
.card-therapie-von-kopfschmerzen,
.card-therapie {
    background-image: url('../img/cards/88838902.CACHEBUSTER.jpg');
}
.card-hausmittel,
.card-natuerliche-hausmittel,
.card-kopfschmerzen-diese-hausmittel-helfen,
.card-natuerlichehausmittel {
    background-image: url('../img/cards/hausmittel.CACHEBUSTER.jpg');
}
.card-meditation,
.card-meditatives-laufen,
.card-kopfschmerzen-loswerden-durch-meditatives-laufen,
.card-newsmeditativeslaufen {
    background-image: url('../img/cards/000069927903.CACHEBUSTER.jpg');
}
.card-meditation-large {
    background-image: url('../img/cards/000069927903_Large.CACHEBUSTER.jpg');
}
.card-interview,
.card-experteninterviews,
.card-experteninterview,
.card-kopfschmerzen-ein-interview-mit-dem-experten {
    background-image: url('../img/cards/interview.CACHEBUSTER.jpg');
}
.card-podcast,
.card-expertenpodcasts,
.card-expertenpodcast,
.card-kopfschmerzenpodcasts-zum-kostenlosen-download {
    background-image: url('../img/cards/podcast.CACHEBUSTER.jpg');
}
.card-knowhow {
    background-image: url('../img/cards/92506512.CACHEBUSTER.jpg');
}
.card-hilfreiche-adressen,
.card-kopfschmerzen-adressen-die-wichtig-sind,
.card-hilfreicheadressen {
    background-image: url('../img/cards/520180218.CACHEBUSTER.jpg');
}
.card-klinik,
.card-schmerzkliniken,
.card-adressen-schmerzkliniken,
.card-mit-kopfschmerzen-in-die-schmerzklinik-adressen-und-tipps,
.card-adressenkliniken {
    background-image: url('../img/cards/klinik.CACHEBUSTER.jpg');
}
.card-selbsthilfe,
.card-selbsthilfegruppen,
.card-adressen-selbsthilfegruppen,
.card-kopfschmerzen-selbsthilfegruppen-im-ueberblick,
.card-adressenselbsthilfe {
    background-image: url('../img/cards/77669700.CACHEBUSTER.jpg');
}
.card-augenschmerzen,
.card-wissenwertes-augenschmerzen,
.card-kopfschmerzen-oder-augenleiden-so-finden-sie-es-heraus,
.card-kopfschmerzen-und-auge,
.card-wissenswertesaugenschmerzen {
    background-image: url('../img/cards/augen.CACHEBUSTER.jpg');
}
.card-apothekenfinder,
.card-hilfe-aus-der-apotheke,
.card-kopfschmerzen-alle-apotheken-in-ihrer-naehe,
.card-servicesapothekenfinder,
.card-hilfeausderapotheke {
    background-image: url('../img/cards/apotheke.CACHEBUSTER.jpg');
}

.card-migraene-radar,
.card-migraene-radar-20,
.card-servicesmigraeneradar {
  background-image: url('../img/cards/migraene-radar.CACHEBUSTER.jpg');
}

.card-ampeltest {
  background-image: url('../img/cards/frau-im-bett.CACHEBUSTER.jpg');
}

.card-kopfschmerzexperten {
  background-image: url('../img/cards/klinik.CACHEBUSTER.jpg');
}

.card-tai-chi,
.card-taichi,
.card-kopfschmerzen-mit-taichi-vorbeugen,
.card-taichi-uebungen,
.card-kopfschmerzenvorbeugentaichi {
    background-image: url('../img/cards/tai-chi.CACHEBUSTER.jpg');
}
.card-tai-chi-large {
    background-image: url('../img/cards/tai-chi-large.CACHEBUSTER.jpg');
}
.card-in-der-nacht-von-kopfschmerzen-geweckt-koffein-hilft,
.card-wecker,
.card-news-kopfschmerzen-nachts-koffein,
.card-weckerkopfschmerzen,
.card-newskoffeingegenkopfschmerzennachts {
    background-image: url('../img/cards/wecker.CACHEBUSTER.jpg');
    background-position: center;
}
.card-wecker-large {
    background-image: url('../img/cards/wecker-large.CACHEBUSTER.jpg');
}

.card-yoga,
.card-yoga-uebungen,
.card-therapie-yoga,
.card-yoga-vier-uebungen-und-atmen-gegen-kopfschmerzen,
.card-kopfschmerzenvorbeugenyoga {
    background-image: url('../img/cards/yoga.CACHEBUSTER.jpg');
    background-position: center;
}
.card-yoga-large {
    background-image: url('../img/cards/yoga-large.CACHEBUSTER.jpg');
}

.card-gedaechtnisschwaeche,
.card-newsgedaechtnisschwaeche {
    background-image: url('../img/cards/news-gedaechtnisschwaeche.CACHEBUSTER.jpg');
    background-position: center;
}
.card-gedaechtnisschwaeche-large {
    background-image: url('../img/cards/news-gedaechtnisschwaeche-large.CACHEBUSTER.jpg');
    background-position: center;
}

.card-pmr,
.card-kopfschmerzenvorbeugenpmr,
.card-progressive-muskelentspannung-nach-jacobson {
    background-image: url('../img/cards/pmr.CACHEBUSTER.jpg');
    background-position: center;
}

.card-pmr-large {
    background-image: url('../img/cards/pmr-large.CACHEBUSTER.jpg');
}

.card-nackenverspannung,
.card-spannungskopfschmerzen-in-verbindung-mit-nackenschmerzen,
.card-kopfschmerzen-mit-nackenverspannung {
  background-image: url('../img/cards/nackenverspannung.CACHEBUSTER.jpg');
}


.card-kopfschmerzursachennackenverspannung,
.card-ursachen-nackenverspannung,
.card-spannungskopfschmerzen-in-verbindung-mit-nackenverspannungen,
.card-kopfschmerzen-mit-nackenverspannungen {
  background-image: url('../img/cards/kopfschmerz-ursachen-nackenverspannung.CACHEBUSTER.jpg');
}

.card-kopfschmerzenvorbeugenspannungskopfschmerzen,
.card-kopfschmerzen-vorbeugen-spannungskopfschmerze,
.card-nackenverspannungen-gezielt-loesen,
.card-kopfschmerzen-vorbeugen-spannungskopfschmerzen {
  background-image: url('../img/cards/kopfschmerzen-vorbeugen-spannungskopfschmerzen.CACHEBUSTER.jpg');
}

.card-kopfschmerzen-bei-kindern,
.card-newskinder {
  background-image: url('../img/cards/news-kinder.CACHEBUSTER.jpg');
}

.card-triptane,
.card-triptane-bei-migraene,
.card-wirkstoffetriptane {
  background-image: url('../img/cards/triptane.CACHEBUSTER.jpg');
}

.card-migraene-im-alter,
.card-migraeneimalter {
  background-image: url('../img/cards/migraene-alter.CACHEBUSTER.jpg');
}

.card-nacken-large {
  background-image: url('../img/cards/nacken-large.CACHEBUSTER.jpg');
}

.card-frau-im-bett {
  background-image: url('../img/cards/frau-im-bett.CACHEBUSTER.jpg');
}

.card-migraene-macht-reizbarer,
.card-migraenemachtreizbarer {
  background-image: url('../img/cards/migraene-gehirn.CACHEBUSTER.jpg');
}

.card-augenuebungen-bei-kopfschmerzen,
.card-kopfschmerzenvorbeugenaugenuebungen {
  background-image: url('../img/cards/kopfschmerzen-vorbeugen-augen.CACHEBUSTER.jpg');
  background-position: center;
}
