

.subnavigation-bar {
  .list-inline-item {
    margin-bottom:  $navbar-padding-vertical;
  }

  .list-inline {
      padding: $navbar-padding-vertical 0;
      margin-bottom: 0;
      margin-left: -5px;

      > li {
        line-height: 1;
        padding-bottom: 10px;
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;

        > a {
          display: block;
          position: relative;
          padding: 6px 9px;
          background-color: transparent;
          border: 1px solid rgba($primary, .5);
          border-radius: 0;
          color: rgba($primary, .8);
          text-align: center;
          font-size: 16px;
          font-weight: normal;
          font-family: $font-family-condensed;
          font-weight: 400;
          background-color: lighten($brand-lightblue, 3%);

          &:after {
            content: '\00a0\203A';
            color: $brand-classic;
          }

          &:hover,
          &:focus {
            color: $primary;
            background-color: lighten($brand-lightblue, 1%);
          }
        }

        &.active {
          > a {
            color: $primary;
            background-color: lighten($brand-lightblue, 1%);
          }
        }

        &:last-child {
          padding-right: 0;
        }


/*        @include media-breakpoint-down(sm) {
            display: block;
        }*/

      }
  }

  .btn-group {
      margin: $navbar-padding-vertical 0;

      > .btn-default {
        padding: 6px 9px;
        background-color: transparent;
        border: 1px solid rgba($primary, .5);
        border-radius: 0;
        color: rgba($primary, .8);
        font-size: 16px;
        font-weight: normal;
        font-family: $font-family-condensed;
        font-weight: 400;

        &:hover,
        &:focus {
          color: $primary;
          background-color: lighten($brand-lightblue, 2%);
        }
      }

      .dropdown-menu {
        background-color: white;

        li {
          padding: 0 0;
          background-color: transparent;
          border-radius: 0;

          + li {
            border-top: 1px solid rgba($primary, .05);
          }

          &.level-3 {
            padding: 0 16px;
          }

          a {
            padding: 6px 9px;
            color: rgba($primary, .8);
            text-align:left;
            font-size: 15px;
            font-weight: normal;
            font-family: $font-family-condensed;
            font-weight: 400;

            &.nextlevel {
              font-size: 16px;
            }

          }

          &.active a {
            font-weight: bold;
          }

        }

      }

  }

}
