
.intro {
    background-color: $brand-lightblue;
    padding-bottom: 10px;

    > .container {
        max-width: $content-width-max;
        margin: 0 auto;
    }

    @include media-breakpoint-down(sm) {
        margin-top: -10px;
    }
}

.squeeze {
    position: relative;
    padding-bottom: 4em;
    height: 200px;
    overflow: hidden;

    h1, h2, h3,
    .h1, .h2, .h3 {
        text-align: center;
    }

    > .squeeze-bottom {
        position: absolute;
        width: 100%;
        height: 50px;
        left: -1px;
        bottom: -10px;
        background-color: $brand-lightblue;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 30px;
            bottom: 48px;
            @include gradient-y($start-color: rgba($brand-lightblue, 0.05), $end-color:  rgba($brand-lightblue, 0.99));
        }

        > .btn-like {
            position: absolute;
            top: 10px;
            right: $grid-gutter-width;
            text-transform: none;
            @include transition(opacity 0.6s ease);
            color: $primary;
        }
        > .squeeze-squeeze {
            display: none;
        }
    }

    &.expanded {
        max-height: none;

        > .squeeze-bottom {

            > .squeeze-expand {
                display: none;
            }

            > .squeeze-squeeze {
                display: block;
                bottom: 10px;
            }
        }
    }
}
