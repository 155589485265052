@include media-breakpoint-down(sm) {
  .col-xxs600-12 {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 479px) {
.col-xxs-12 {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

  .no-float-xxs {
    float: none;
  }
}
