
.hyphenate {
    @include hyphens(auto);
}

.donthyphenate {
    @include nobr();
}
.nobr,
.nowrap,
.neverwrap {
    @include nobr();
    white-space: nowrap;
}

.ellipse,
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-margin {
    margin: 0;
}
.no-border {
    border: 0;

    > tbody > tr > td {
        border: 0;
    }
}

.center-vertical,
.table > tbody > tr > td.center-vertical {
    display: table-cell;
    vertical-align: middle;
}

.faded {
    display: none;
}

.center-vertical {
    @include flexbox();
//    @include justify-content(center);
    @include align-items(center);
}

.center-horizontal {
    margin: 0 auto;
//    @include align-items(center);
}


.align-right {
    float: right;
}
.align-left {
    float: left;
}

@include media-breakpoint-down(sm) {
  .no-float-xs {
    float: none;
  }
}

.relativ {
    position: relative;
}

.svg-max {
    width: 100%;
    height: auto;
    margin: floor(( $line-height-computed / 2 )) 0;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.appear.animated {
  animation-name: fadeIn;
  opacity: 1;
}

.slideInDown {
  animation-name: slideInDown;
}

.pull-right {
  float: right !important;
}

.img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}

.hide {
  display: none !important;
}
