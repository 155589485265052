@import "node_modules/bootstrap/scss/type";

p {
  margin: 0 0 12.5px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        @include hyphens(auto);
    }
}

.h1,
.h2,
.h3,
.ss-result__title,
h1,
h2,
h3 {
  margin-top:25px;
  margin-bottom:12.5px
}

.content-page,
.inception {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
      color: $primary; // $text-muted;
      @include nobr();
  }

  h1 {
    margin-top: 0;
  }

  a:not([class]) {
    color: $primary;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: $brand-classic;
    }
  }

  h4, .h4,
  h5, .h5,
  h6, .h6 {
      margin-top: $line-height-computed;
      margin-bottom: ($line-height-computed / 2);
  }
}


mark,
.mark {
  background-color: $brand-lightblue;
}


.intro,
.streifen {
  a:not([class]) {
    color: $primary;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: $brand-classic;
    }
  }
}

.regular {
    font-weight: 400;
}

dd {
  margin: 0 0 ($line-height-computed / 2);
}


sup {
  opacity: 0.67;

  &.reg {
    display: inline-block;
    transform: scale(0.67) translateX(-0.15em) translateY(0.25em);
    opacity: 0.4;
  }
}
.ie {
  sup {

    &.reg {
      display: inline-block;
      transform: scale(0.67) translateX(-0.1em) translateY(0.1em);
      opacity: 0.4;
    }
  }
}

@media (max-width: 639px) {
    body {
        .content {
            font-size: 15px;

            small, .small {
                font-size: $font-size-sm;
            }
        }

        h1, .h1  { font-size: 22px; }
        h2, .h2  { font-size: 18px; }
        h3, .h3  { font-size: 16px; }
        h4, .h4  { font-size: 14px; }
        h5, .h5  { font-size: 12px; }
        h6, .h6  { font-size: 12px; }

    }

    p, li, dt, dd, td, th {
       @include hyphens(auto);
    }
}

.text-intensiv,
.content-page .text-intensiv {
    color: $brand-intensiv;
}
.text-classic,
.content-page .text-classic {
    color: $brand-classic;
}
.text-tension,
.content-page .text-tension {
    color: $brand-tension;
}

.small, small {
  @include font-size(66%);
  font-weight: inherit;
}

strong.small {
  font-weight: 700;
}

.text-muted {
  color: $text-muted !important;
}
