
.card-light {
  border-radius: 0;
  box-shadow: none;
}

.white {
  background-color: $white;
//    margin-bottom: 5em;
}

@import "./intro";
@import "./verteiler-list";
@import "./streifen";

.mood-row {
  @include media-breakpoint-down(sm) {
    > .container-md {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.mood-block {
  height: 185px;
  background-position: top center;
  background-size: cover;

  @media (min-width: 540px) {
    height: 240px;
  }
  @include media-breakpoint-up(md) {
    height: 370px;
  }

}

.inception,
.the-content-itself {
  > * {
    max-width: $content-width-max;
    margin-left: auto;
    margin-right: auto;
  }
  > .img-fluid {
    max-width: 100%;
  }
}

.teaserslide {
  margin-top: $grid-gutter-width;
}


.second-level {
  padding-left: 40px;
}

.footer {
//  margin-bottom: 2em;

  > .container {
    padding-top: 15px;
  }
}


.disclaimer {
  font-size: 13px;
  font-weight: 400;
  color: $text-muted;
}


.slider {
  overflow: hidden;
  @include opacity(0);
}

.sanofi-logo {
  @include media-breakpoint-down(md) {
    text-align: right;
    margin-top: floor($grid-gutter-width / 2);
  }
}

@import "./forms";
@import "./icons";
@import "./wtfforms";
@import "./pflichtpop";
@import "./search";
@import "./sharing";
@import "./responsive-addons";


$btdsgn-li-width: 1.33rem;
.btdsgn-ul {
  color: $text-muted;
  margin-top: 2rem;
  margin-bottom: 1em;
  margin-left: $btdsgn-li-width;
  border-top: 1px solid rgba($text-muted, 0.1);
  padding-top: 1rem;
  padding-left: 0;
  list-style-type: none;
  > li {
    position: relative;

    .btdsgn-li {
      position: absolute;
      left: -$btdsgn-li-width;
      width: 1rem;
      top: 0.67rem;
      font-size: 1rem;
    }
  }
}
