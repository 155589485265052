//
// Cards
// --------------------------------------------------

.teasers {
    padding: 0;
    opacity: 0;
    max-height: 240px;
    overflow: hidden;
    @include transition(opacity 0.67s ease);
    @media (min-width: 600px) {
        padding: 0 60px;
    }

    $slick-prev-character: "\f104";
    $slick-next-character: "\f105";
    /* Arrows */
    .slick-prev,
    .slick-next {
      @include media-breakpoint-down(md) {
        height: 60px;
        width: 50px;
        top: 50%;
        &,
        &:hover,
        &:focus {
            background-color: rgba($brand-lightblue,.8);
        }
      }
    }
    .slick-prev:before, .slick-next:before {
      @include media-breakpoint-down(sm) {
        font-size: 60px;
        opacity: 1;
      }
    }
    @media (max-width: 600px) {
      .slick-prev:before, .slick-next:before {
        display: block;
      }
    }
    .slick-prev {
      @include media-breakpoint-down(md) {
        left: 0;
      }
    }
    .slick-next {
      @include media-breakpoint-down(md) {
        right: 0;
      }
    }

    .slick-slide {
      margin: 20px 15px;
    }
}

// Base class
.teaser {
    position: relative;
//    height: 275px;
//    width: 100%;
//    margin: 20px 15px;
    @include
    shadow-z-1();
    @include transition(all 0.33s ease);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    &:before {
        content: "";
        display: block;
        padding-top: 200px;

        @include media-breakpoint-down(sm) {
            padding-top: 150px;
        }

    }

    &:hover,
    &.slick-current {
        @include shadow-z-2();
    }

    &.lazyload {
      background-image: none !important;
    }
}

.teaser-square:before {
    padding-top: 100%;
}
.teaser-23:before {
    padding-top: 67%;
}


.teaser-content {
    position: absolute;
    width: 100%;
    height: 67%;
    bottom: 0;
    color: $white;
    font-weight: 700;
    padding: 0 15px;
    @include gradient-y($start-color: transparent, $end-color: rgba($primary, .9));

    @media (max-width: 480px) {
        height: 99%;
        bottom: 0;
        text-shadow: 1px 1px 4px $primary;
    }

    @include media-breakpoint-down(sm) {
        text-shadow: 1px 1px 4px rgba($primary, 0.67);
    }

    .teaser-headline {
        font-weight: 700;
        text-transform: uppercase;
//        font-size: 20px;
        color: $white;
/*
        @media (min-width: 600px) and (max-width: $screen-xs-max) {
            font-size: 16px;
        }

        @media (min-width: $screen-xs) and (max-width: 599px) {
            font-size: 12px;
        }
 */
    }

    .teaser-inner {
        position: absolute;
        bottom: 5px;
        padding: 0 5px;

        @include hyphens(auto);

        .teaser-headline {
            margin-top: -($line-height-computed / 2);

            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }


    }

}

.card-experteninterviews,
.card-apothekenfinder,
.card-augenschmerzen,
.card-kopfschmerzen-mit-kombinationspraeparaten-lindern,
.card-eeg,
.card-sport,
.card-was-ist-migraene,
.card-stressbewaeltigungstraining,
.card-kopfschmerzen-am-hinterkopf,
.card-fluessigkeitsmangel {
        background-position: center top;
}
