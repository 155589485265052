@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/opacity";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/reset-filter";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";
// Core mixins
@import "./mixins_flex";  // flexbox

@mixin flex-shrink($width) {
  -webkit-flex-shrink: $width;
      -ms-flex-negative: $width;
          flex-shrink: $width;
}

@mixin nobr() {
  word-wrap: normal;
  -webkit-hyphens: none;
     -moz-hyphens: none;
      -ms-hyphens: none; // IE10+
       -o-hyphens: none;
          hyphens: none;
}

@mixin shadow-z-1(){
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.12),
    0 1px  6px 0 rgba(0, 0, 0, 0.12);
}

@mixin shadow-z-2(){
  box-shadow:
    0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


@mixin button-option($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;


    &:focus,
    &.focus {
        color: lighten($color, 20%);
        background-color: darken($background, 5%);
        border-color: darken($border, 15%);
        @include shadow-z-1();
    }
    &:hover {
        color: lighten($color, 20%);
        background-color: darken($background, 5%);
        border-color: darken($border, 7%);
        @include shadow-z-1();
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);

        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: darken($background, 17%);
            border-color: darken($border, 25%);
            @include shadow-z-1();
        }
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}
