@import "scss/card";

.card {
    box-shadow: 0 0 6px rgba($brand-classic, .12);
    @include transition(all 0.33s ease);

    &:hover,
    &.hovered {
        @include shadow-z-2();
    }
}

.card-header {
  background-color: $brand-lightblue;
  border-color: $brand-lightblue;
  padding: $card-spacer-x $card-spacer-y;
  border-bottom: $card-border-width solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-weight: 700;
}

.card-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 21px;
  font-weight: 400;
}

.card-body {
  padding: $card-spacer-y;
}
