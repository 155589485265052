//
// Streifen
// --------------------------------------------------


// Base class
.streifen {
    width: 100%;
    height: auto;
    overflow: hidden;

    > div {
        padding: 39px 0;
    }

    @include media-breakpoint-up(md) {
        > div {
            padding: 38px 0;
        }
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-top: 0;
        color: $primary;
    }

    .text-white {
        color: $white;
    }

    .streifen-content,
    .content-wirkweise {
        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }
}


.streifen-lg {
    > div {
        padding: 78px 0;
    }
    .padding-col {
        padding-top: 78px;
        padding-bottom: 78px;
    }
    > div.container-no-padd {
        padding-top: 0;
        padding-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
        > div {
            padding: 39px 0;
        }

        .padding-col {
            padding-bottom: 0;
        }


    }

}


// Sizes
@include media-breakpoint-up(md) {
    .streifen-lg {
        > div {
            padding: 104px 0;
        }
        .padding-col {
            padding-top: 104px;
            padding-bottom: 104px;
        }

        > div.container-no-padd {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
.streifen-sm {
    > div,
    .padding-col {
        padding: 24px 0;
    }
}

.streifen-weiss {
    background-color: $white;

    .streifen-content {
        background-color: rgba(255,255,255,0.9);

        $shadow: 0 0 10px rgba(255, 255, 255, .9);
        @include box-shadow($shadow);

    }
}

.streifen-primary {
    background-color: $primary;
    color: $white;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $white;
    }
}

.streifen-lightblue {
    background-color: $brand-lightblue;
}


.white-gradient {
    @include gradient-y(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.1));
}
.white-gradient-invers {
    @include gradient-y(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7));
}

.classic-gradient {
    @include gradient-y(rgba($brand-classic, 0.7), transparent);
}
.classic-gradient-invers {
    @include gradient-y(transparent, rgba($brand-classic, 0.7));
}

.primary-gradient {
    @include gradient-y(rgba($primary, 0.7), transparent);
}
.primary-gradient-invers {
    @include gradient-y(transparent, rgba($primary, 0.7));
}

.intensiv-gradient {
    @include gradient-y(rgba($brand-intensiv, 0.7), transparent);
}
.intensiv-gradient-invers {
    @include gradient-y(rgba(255,255,255,.2), rgba($brand-intensiv, 0.5));
}
