@import "node_modules/bootstrap/scss/breadcrumb";


.breadcrumb-row {
  @media (max-width: $screen-sm) {
    > .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.breadcrumb {

  > li {
    display: inline-block;

    padding-top: 12px;
    padding-bottom: 12px;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      font-family: "Font Awesome 5 Free";
      $nbsp: "\00a0";
      content: "#{$breadcrumb-divider}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
    }
  }
}
