//
// Navbar bootstrap-sass; "show" instead of "in" or "open"
// --------------------------------------------------

.navbar-collapse {
  overflow-x: visible;
  border-top: 1px solid transparent;
  @include clearfix;
  -webkit-overflow-scrolling: touch;

  &.show {
    overflow-y: auto;
  }
}

// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggler {
  padding: 9px 10px 9px 0;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.site-header {
  @include media-breakpoint-down(sm) {
    .container {
      max-width: 100vw;
    }
  }
}

.support-bar {
  background-color: white; //$primary;
  padding: 4px 10px 5px;

  > .container {
    font-size: 14px;
    line-height: 1;

    > small {
      font-size: 12px;
      line-height: 1;
    }

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }
}

.main-menu {
  border-top-width: 0;

  .navbar-collapse.show {
    display: block;
  }

  @include media-breakpoint-up(md) {
    .navbar-collapse.collapse {
      display: flex;
    }
  }
}

.topline {
  padding: 0 0;
  margin-bottom: 0;
  height: 55px;
  min-height: 55px;
  border-top-width: 0;
  background-color: white;

  .navbar-header {
    display: flex;
    align-items: center;
    padding: 0;


    @include media-breakpoint-down(sm) {
      width: 100vw;
      padding: 0 15px;
    }

      .navbar-logo {
        position: static;
        margin: -1px auto 0 6%;
        display: flex;
        justify-content: center;
        align-self: center;
        height: auto;

        img {
          width: 256px;
          @media (max-width: $screen-sm) {
            width: 190px;
          }
        }
      }
  }

  .navbar-right {
    margin-right: 0;
    margin-left: auto;
    flex-direction: row;
  }


  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    height: 55px;

    &.navbar-default .navbar-misc {
      > li > a {
        color: $primary;
        padding: 3px $navbar-padding-horizontal;
        margin: 0 0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    height: 55px;

    &.stuck {
      right: 0;
      left: 0;
      background-color: rgba(255,255,255,.95);
      z-index: $zindex-navbar-fixed;
      top: 0!important;
      box-shadow: $box-shadow;
    }
  }

}

@include media-breakpoint-down(sm) {
  .navbar {
    min-height: 0;
  }
}

// Default navbar
.navbar-default {
  background-image: none;
  box-shadow: none;

  .navbar-home-link {
    color: $primary;

    .fa-home {
      font-size: 20px;
    }
  }
  @include media-breakpoint-up(md) {
    .row {
      width: 100vw;
    }

    .navbar-home-link {
      display: flex;
      align-self: center;
      margin-left: 15px;
  }
    @include media-breakpoint-up(lg) {

      .navbar-home-link {
        margin-left: 17px;

        .fa-home {
          font-size: 28px;
        }
      }
    }
  }

  .navbar-nav {
    &.navbar-main {
      > li {
        > a {
          font-size: 20px;
          font-family: $font-family-condensed;
          font-weight: 700;
          text-transform: uppercase;
          color: $body-color;

          &:hover,
          &:focus {
            color: $navbar-default-link-hover-color;
            background-color: $navbar-default-link-hover-bg;
          }

          @include media-breakpoint-up(lg) {
            font-size: 24px;
            padding-top: 2px;
            padding-bottom: 2px;
          }
          @include media-breakpoint-up(xl) {
            font-size: 28px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 1.33;
            color: $primary;

            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            width: 100%;
          }

          padding-left: 0;
          padding-right: 0;

          .link {
            display: none;
          }

          .angle {
            @include media-breakpoint-up(md) {
              display: block;
              position: absolute;
              right: 0;
              top: 50%;
              font-size: 55%;
              transform: translate(150%, -50%);
            }
          }
        }

        .dropdown-menu {
          padding-top: 0;
          margin-top: 0;
          margin-left: 0;
          box-shadow: none;

          @include media-breakpoint-up(md) {
            &.show {
              position: absolute;
              box-shadow: 0 6px 6px rgb(0 68 119 / 18%);
            }
          }


          li {
            > a {
              padding: 6px 20px 6px 0;
              color: $navbar-default-link-color;
              font-size: 17px;

              @include media-breakpoint-up(md) {
                padding: 6px 20px;
              }

              &:hover,
              &:focus {
                color: $navbar-default-link-hover-color;
              }

            }
            + li {
              border-top: 1px solid $gray-lighter;
            }
          }

          > .active > a {
            color: $brand-classic;
          }
        }

        @include media-breakpoint-up(md) {
          &.active > a > span {
            color: $brand-classic;
            border-bottom: 4px solid $brand-classic;
          }
        }

        // show dropdowns
        &.show > a {
          &,
          &:hover,
          &:focus {
            border-color: transparent;
          }

          .angle {
            display: none;
          }
          .link {
            display: inline;
            > i {
              @include scale(.67);
            }
            @include media-breakpoint-up(md) {
              display: block;
              position: absolute;
              right: 0;
              top: 50%;
              font-size: 50%;
              transform: translate(150%, -50%);
              color: $brand-classic;
              > i {
                @include scale(1);
              }
            }
          }

        }
      }

      @include media-breakpoint-down(sm) {
        > .active {
          > a {
            &,
            &:hover,
            &:focus {
                > span {
                  color: $brand-classic;
                }
            }
          }
        }
        .dropdown-menu .active a {
          color: $brand-classic;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      line-height: 2;
      padding-left: 15px;
      flex-direction: column;
    }
  }

  .navbar-toggle-wrapper {
    align-self: flex-end;
  }

  .navbar-toggler {
    $bar-thickness: 2px;
    $bar-transition-duration: 0.2s;
    $navbar-dark-color: $primary;

    &:active,
    &:focus,
    &:active:focus {
      outline: none;
    }

    &:after {
      content: 'Menü';
      position: relative;
      top: 9px;
      left: 0;
      font-family: $font-family-sans-serif;
      font-size: .8rem;
      opacity: .8;
      @include media-breakpoint-down(sm) {
        top: 1px;
        left: 3px;
        font-size: 11px;
      }
    }

    span {
      display: block;
      position: relative;
      top: 6px;
      @include media-breakpoint-down(sm) {
        top: 0;
      }
      left: 3px;
      height: $bar-thickness;
      width: 26px;
      white-space: nowrap;
      text-indent: -1000rem;
      font-size: $bar-thickness;
      background: $navbar-dark-color;


      &::before,
      &::after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: $bar-thickness;
        background-color: $navbar-dark-color;
        content: "";
      }
      &:before {
        top: -6px;
      }
      &:after {
        bottom: -6px;
      }
    }

    &.hamburger--htx {
      span {
        transition-property: background;
        transition-duration: 0s, $bar-transition-duration;
        transition-delay: 0s, $bar-transition-duration;

        &:before,
        &:after {
          transition-duration: $bar-transition-duration, $bar-transition-duration;
          transition-delay: 0s;
        }
        &:before {
          transition-property: top, transform;
        }
        &:after {
          transition-property: bottom, transform;
        }
      }

      /* active state, i.e. menu show */
      &:not(.collapsed) {
        span {
          background-color: transparent;

          &:before {
            top: 0;
            transform: rotate(45deg);
          }
          &:after {
            bottom: 0;
            transform: rotate(-45deg);
          }
          &:before,
          &:after {
            transition-delay: 0s, $bar-transition-duration;
          }
        }
      }
    }
  }

  .navbar-icons  {
    margin-right: 5px;
    order: 2;

    > ul {
      > li {
        > a {
          display: flex;
          padding: 15px 10px 18px;
          color: $primary;

          &:hover,
          &:focus {
            color: $link-color;
          }
        }
      }
    }
  }

  .navbar-justified {

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    > li {
      > a {
        white-space: nowrap;
        text-align: center;
        padding-left: 10px;
        font-size: 15px;
      }


      @include media-breakpoint-up(md) {
        &:first-child {
          > a {
            text-align: left;
          }
        }
        &:last-child {
          > a {
            text-align: right;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      > li {
        display: flex;
        opacity: 1;

        &:last-child {
          margin-right: 24px;

          .dropdown-menu {
            margin-left: 0;
            margin-right: -20px;
          }
        }

      }
    }

    @include media-breakpoint-down(sm) {

      > li {
        > a {
          text-align: left;
          border-bottom: 1px solid $gray-lighter
        }
      }
    }
  }


  .navbar-misc {
    > li > a {
      font-family: $font-family-condensed;

      font-weight: 400;
      font-size: 13px;
      line-height: 1.24;
      color: $primary;
      padding: 3px $navbar-padding-horizontal;
      margin: 0 0;

      display: inline-block;
      border-left: 1px solid $primary;
    }
  }


  &.main-menu {
    margin-bottom: 0;

    .navbar-misc {
      display: none;
      width: 0;
    }
  }


  &.stuck {
    min-height: 0;
    .navbar-justified {
      > li {
        > a {
          padding-top: 11px;
          padding-bottom: 11px;
        }
      }
    }


    @include media-breakpoint-up(lg) {
      .navbar-justified {
        width: calc(100% - 8.25px);
        > li {
          > a {
            padding-left: 20px;
            font-size: 18px;
          }
        }
      }
    }
    @include media-breakpoint-up(xl) {
      .navbar-justified {

        > li {
          > a {
            padding-left: 50px;
            font-size: 22px;
          }
        }
      }
    }
  }
}

.navbar-hide {
  display: none;
}

.topline-search {
  position: absolute;
  left: ($navbar-padding-horizontal - 1);
  z-index: 9;
  top: 54px;
  margin: 0;
  padding: 0;
  width: calc(100% - 28px);
  height: auto;
  background-color: rgba(255,255,255,.99);
  box-shadow: $box-shadow;


  > .input-group {
    padding: 0 $navbar-padding-horizontal $navbar-padding-horizontal;

    > .form-control {
      height: 51px;
    }
  }


  @include media-breakpoint-down(sm) {
    top: 59px;
  }

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    top: 74px;
  }

  &.navbar-search {
    top: 46px;
  }


  > .input-group {
    width: 100%;
    padding: floor($navbar-padding-vertical/2) $navbar-padding-horizontal;

    > .input-group-btn {
      .btn {
        padding: 12px $grid-gutter-width;
      }
    }
  }
}



@include media-breakpoint-up(md) {
  .stuck {
    &.main-menu {
      position: fixed;
      right: 0;
      left: 0;
      background-color: rgba(255,255,255,.95);
      z-index: $zindex-navbar-fixed;
      top: 0!important;
      box-shadow: $box-shadow;

      .navbar-misc {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: auto;
      }
    }

    .navbar-justified {

      > li {
        display: flex;
        width: auto;
      }
    }
  }
}
@include media-breakpoint-down(sm) {

  .navbar.main-menu {
    padding: 0;

    .row {
      width: 100%
    }
  }


  .mobstuck {
    &.main-menu {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: ($zindex-navbar - 1);
      background-color: white;
      box-shadow: $box-shadow;
      padding: 0;

    }
    &.stuck {
      top: 55px!important;
    }
  }
}

#backlink {
  .btn-like {
    font-size: 16px;
  }
}
