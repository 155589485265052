@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";

.navbar-main {
  .dropdown-menu {
    font-family: $font-family-condensed;
    font-size: ceil(($font-size-base * 0.92));
    margin: 0 0; // override default ul
    border-radius: 0;
    @include box-shadow(0 6px 6px rgba($primary, .175));
    animation-duration: 200ms;

    @include media-breakpoint-up(md) {
      @include translate(0, -9px);
    }

    .divider {
      margin: 6px 0;
    }
  }

  @include media-breakpoint-up(md) {
    > li:last-child {
      // The dropdown menu (ul)
      .dropdown-menu {
        right: 0;
        left: auto;
        text-align: right;
      }
    }
  }
}

.navbar-default.stuck {
  .navbar-nav.navbar-main {
    > li .dropdown-menu {
      /* left: 50%;
      right: auto; */


      margin-left: 0;


      @include media-breakpoint-up(md) {
        @include translate(0, 0);
      }

      @include media-breakpoint-up(lg) {
        @include translate(20px, 0);
      }
      @include media-breakpoint-up(xl) {
        @include translate(30px, 0);
      }

      // text-align: center;
    }

    @include media-breakpoint-up(md) {
      > li:last-child {
        // The dropdown menu (ul)
        .dropdown-menu {
          @include translate(0, 0);
          right: 0;
          left: auto;
          // text-align: center;
        }
      }
    }
  }

  &.mobstuck {
      .navbar-main {
        .dropdown-menu {
          left: 0;
          text-align: left;
          @include translate(0, 0);
        }
      }
  }
}

.dropdown-menu.show {
  display: block;
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}
