.verteiler-list {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
}
.media-list {
  padding-left: 0;
    .media {
        background-color: $white; // $brand-lightblue;
        @include shadow-z-1();
        @include transition(all 0.33s ease);
        margin-top: 0;
        flex-direction: column;
        align-items: initial;

      @media (min-width: 450px) {
        flex-direction: row;
      }

        &:hover {
            @include shadow-z-2();
        }

        .media-body {
            padding: ($grid-gutter-width / 2) ($grid-gutter-width / 2) 5px;
        }

        @include media-breakpoint-up(lg) {
            .media-body {
                padding: 55px $grid-gutter-width;
            }
        }

        + .media {
            margin-top: 20px;
        }

        &.disabled {
            cursor: not-allowed;

            @include opacity(.67);

            &:hover {
                @include shadow-z-1();
            }
        }
    }
}

.media-left {
    color: $white;
    font-weight: 700;
    width: 37.8%;
    background-position: center;
    background-size: cover;

    > .h2,
    > .h3 {
        font-weight: 700;
    }

    @media (max-width: 449px) {
        display: block;
        width: 100%;
        height: 160px;
        float: none;
    }
}

.media > .lazyload {
  background-image: none !important;
}

.media-hinterkopf,
.media-kopfschmerzen-am-hinterkopf {
    background-image: url('../img/cards/hinterkopf.CACHEBUSTER.jpg');
}
.media-stirn,
.media-kopfschmerzen-an-stirn-und-schlaefe {
    background-image: url('../img/cards/stirn.CACHEBUSTER.jpg');
}
.media-schwanger,
.media-kopfschmerzen-in-der-schwangerschaft {
    background-image: url('../img/media-list/000046117308.CACHEBUSTER.jpg');
}
.media-kurios,
.media-ungewoehnliche-kopfschmerzarten {
    background-image: url('../img/media-list/000069188243.CACHEBUSTER.jpg');
}
.media-infektion,
.media-kopfschmerzen-durch-erkaeltung {
    background-image: url('../img/media-list/000019701923.CACHEBUSTER.jpg');
}
.media-ernaehrung,
.media-kopfschmerzen-durch-ernaehrung {
    background-image: url('../img/media-list/000083145271.CACHEBUSTER.jpg');
}

.media-fluessigkeitsmangel {
    background-image: url('../img/media-list/000075069663.CACHEBUSTER.jpg');
}
.media-stress {
    background-image: url('../img/media-list/000044937048.CACHEBUSTER.jpg');
}
.media-wetter {
    background-image: url('../img/cards/wetter.CACHEBUSTER.jpg');
}
.media-spannungskopfschmerzen-in-verbindung-mit-nackenverspannungen,
.media-kopfschmerzen-mit-nackenverspannung {
  background-image: url('../img/cards/kopfschmerz-ursachen-nackenverspannung.CACHEBUSTER.jpg');
}

.media-migraeneprophylaxe {
    background-image: url('../img/cards/90452380.CACHEBUSTER.jpg');
}

.media-triggerfaktoren,
.media-kontrolle-von-triggerfaktoren {
    background-image: url('../img/cards/stop.CACHEBUSTER.jpg');
}
.media-list .media .media-left.media-sport {
    background-image: url('../img/cards/sport.CACHEBUSTER.jpg');
    background-position: center top;
}
.media-entspannungsmethoden {
    background-image: url('../img/cards/relax.CACHEBUSTER.jpg');
}
.media-biofeedback,
.media-biofeedbacktherapie {
    background-image: url('../img/media-list/000085265649.CACHEBUSTER.jpg');
}
.media-list .media .media-left.media-stressbewaeltigung,
.media-stressbewaeltigungstraining {
    background-image: url('../img/cards/stress.CACHEBUSTER.jpg');
    background-position: center top;
}
.media-akupunktur,
.media-bei-chronischen-kopfschmerzen-hilft-akupunktur {
    background-image: url('../img/media-list/89033293.CACHEBUSTER.jpg');
}

.media-weitere-kopfschmerzarten {
    background-image: url('../img/media-list/94919167.CACHEBUSTER.jpg');
}

.media-mrt,
.media-mrt-eeg-und-ct,
.media-was-macht-der-arzt {
    background-image: url('../img/cards/103729620.CACHEBUSTER.jpg');
}
.media-eeg {
    background-image: url('../img/media-list/103818876.CACHEBUSTER.jpg');
}

.media-wirkstoffe {
    background-image: url('../img/media-list/000041784534.CACHEBUSTER.jpg');
}

.media-wirkstoffe-uebersicht {
    background-image: url('../img/media-list/103818876.CACHEBUSTER.jpg');
}
.media-paracetamol,
.media-paracetamol-ibuprofen-und-as {
    background-image: url('../img/cards/klassiker.CACHEBUSTER.jpg');
}
.media-ass {
    background-image: url('../img/media-list/103818876.CACHEBUSTER.jpg');
}
.media-koffein,
.media-coffein {
    background-image: url('../img/media-list/000023280396.CACHEBUSTER.jpg');
}

.media-weitere-wirkstoffe {
    background-image: url('../img/media-list/103818876.CACHEBUSTER.jpg');
}
.media-kombinationspraeparate {
    background-image: url('../img/media-list/88838902.CACHEBUSTER.jpg');
}

.media-kopfschmerzen-vorbeugen {
    background-image: url('../img/cards/vorbeugen.CACHEBUSTER.jpg');
}

.media-ursachen-fuer-kopfschmerzen {
    background-image: url('../img/cards/123113293.CACHEBUSTER.jpg');
}

.media-alternative-heilmethoden {
    background-image: url('../img/cards/alternativ.CACHEBUSTER.jpg');
}

.media-news-tipps {
    background-image: url('../img/cards/news.CACHEBUSTER.jpg');
}

.media-kopfschnerz-koffein {
    background-image: url('../img/media-list/000023280396.CACHEBUSTER.jpg');
}
.media-kopfschnerz-coffein,
.media-kopfschmerzen-und-koffein {
    background-image: url('../img/cards/coffein.CACHEBUSTER.jpg');
}
.media-augenschmerzen,
.media-kopfschmerzen-und-auge {
    background-image: url('../img/media-list/augen.CACHEBUSTER.jpg');
}

.media-services {
    background-image: url('../img/cards/service.CACHEBUSTER.jpg');
}

.media-kopfschmerztest {
    background-image: url('../img/cards/schmerztest.CACHEBUSTER.jpg');
}
.media-kopfschmerztagebuch {
    background-image: url('../img/cards/100074059.CACHEBUSTER.jpg');
}
.media-apothekenfinder {
    background-image: url('../img/cards/apotheke.CACHEBUSTER.jpg');
}

.media-experten {
    background-image: url('../img/cards/91507759.CACHEBUSTER.jpg');
}
.media-experten-interview,
.media-experteninterviews {
    background-image: url('../img/cards/interview.CACHEBUSTER.jpg');
}
.media-experten-podcast,
.media-expertenpodcasts {
    background-image: url('../img/cards/podcast.CACHEBUSTER.jpg');
}

.media-hilfreiche-adressen {
    background-image: url('../img/cards/520180218.CACHEBUSTER.jpg');
}

.media-adressen-kliniken,
.media-schmerzkliniken {
    background-image: url('../img/media-list/klinik.CACHEBUSTER.jpg');
}
.media-adressen-selbsthilfe,
.media-selbsthilfegruppen {
    background-image: url('../img/media-list/77669700.CACHEBUSTER.jpg');
}

.media-meditation,
.media-meditatives-laufen {
    background-image: url('../img/media-list/000069927903.CACHEBUSTER.jpg');
}
.media-trigger,
.media-kopfschmerztrigger {
    background-image: url('../img/media-list/trigger.CACHEBUSTER.jpg');
}
.media-migraene-radar {
    background-image: url('../img/cards/migraene-radar.CACHEBUSTER.jpg');
}

.media-ampeltest {
    background-image: url("../img/media-list/frau-im-bett.CACHEBUSTER.jpg"); }

.media-tai-chi,
.media-taichi-uebungen {
    background-image: url('../img/cards/tai-chi.CACHEBUSTER.jpg');
    background-position: center bottom;
}
.media-wecker,
.media-weckerkopfschmerzen {
    background-image: url('../img/cards/wecker.CACHEBUSTER.jpg');
    background-position: center;
}
.media-yoga,
.media-yoga-uebungen {
    background-image: url('../img/cards/yoga.CACHEBUSTER.jpg');
    background-position: center;
}
.media-gedaechtnisschwaeche {
    background-image: url('../img/cards/news-gedaechtnisschwaeche.CACHEBUSTER.jpg');
    background-position: center;
}

.media-progressive-muskelentspannung-nach-jacobson{
    background-image: url('../img/cards/pmr.CACHEBUSTER.jpg');
    background-position: center;
}

.media-nackenverspannungen-gezielt-loesen {
  background-image: url('../img/cards/kopfschmerzen-vorbeugen-spannungskopfschmerzen.CACHEBUSTER.jpg');
  background-position: center;
}

.media-kopfschmerzen-mit-nackenverspannungen {
  background-image: url('../img/cards/kopfschmerz-ursachen-nackenverspannung.CACHEBUSTER.jpg');
  background-position: center;
}

.media-kopfschmerzen-bei-kindern {
  background-image: url('../img/cards/news-kinder.CACHEBUSTER.jpg');
  background-position: center;
}

.media-triptane {
  background-image: url('../img/cards/triptane.CACHEBUSTER.jpg');
  background-position: center;
}

.media-augenuebungen-bei-kopfschmerzen {
  background-image: url('../img/cards/kopfschmerzen-vorbeugen-augen.CACHEBUSTER.jpg');
  background-position: center;
}
