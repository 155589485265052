@media (max-width: $screen-sm) {
  // visible-xxs
  .is-visible-xs {
    display: block!important;
  }
}


@include media-breakpoint-down(sm) {
  .is-visible-sm {
    display: block!important;
  }
  .is-hidden-sm {
    display: none!important;
  }
}

@include media-breakpoint-down(md) {

}

@media (min-width: $screen-sm) {
  // visible-xxs
  .is-visible-xs {
    display: none!important;
  }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-md-max) {
  .is-visible-lg {
    display: block!important;
  }
}

@include media-breakpoint-up(xl) {
  .is-visible-xl {
    display: block!important;
  }
}

// Überprüfen
@include media-breakpoint-down(sm) {
  .is-visible-sm {
    display: block!important;
  }
}


@media (max-width: $screen-sm) {
  .is-hidden-xs {
    display: none!important;
  }
}


// überprüfen
@include media-breakpoint-up(md) {
  .is-hidden-md {
    display: none!important;
  }

  .is-visible-sm {
    display: none!important;
  }
}

@media (max-width: $screen-sm) {
  .visible-xxs600{
    display: block;
  }
}
@media (min-width: $screen-sm) {
  .visible-xxs600{
    display: none;
  }
}
