.obligationframe {
    display: none;
    position: fixed;
    z-index: 9002;
    top: 100px;
    left: 0;
    width: 100%;
    height: 175px;
    padding: 15px;
    overflow: hidden;
    font-size: 13px;


    @include media-breakpoint-up(sm) {
      top: 40%;
    }
    /* @include media-breakpoint-up(sm) {
      bottom: 70px;
    }
    @media (min-width: $screen-sm) {
      bottom: 60px;
    }
    @media (min-width: $screen-md) {
      bottom: 30px;
    } */

    .pp-body {
        background: white;
        border: 1px solid $gray-lighter;
        padding: .5em 1em;
        height: 120px;
        @include box-shadow(4px 4px 8px rgba(0,0,0,0.4));
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;


        .disclaimer-box {
            margin-right: 40px;
        }

        p {
            margin-bottom: .5em;
            line-height: 1.33;
        }
    }

    &.bottom {
      bottom: 0;
    }
}

.ppclose {
    position: absolute;
    right: 3px;
    top: 0;
    font-size: 24px;
    line-height: 1;
    overflow: visible;
}
.ppclose:hover,
.ppclose:active,
.ppclose:focus {
    text-decoration: none;
    color: $brand-intensiv;
    @include opacity(1);
}
