
.sharing {
    margin-top: 15px;
    padding: 0 $padding-base-horizontal;
    margin-bottom: 30px;
    @include clearfix();

    .table {
      > tbody
        > tr {
          > td {
            border-top-width: 0;

            &.text-right {
//                padding-right: 15px;
            }
          }
        }
    }

    .fa-share {
        color: $white;
    }

}

.SocialSharingButtons {
    @include clearfix();

    .share-buttons {
        @include clearfix();
        margin-bottom: 0;

        .btn {
            padding: 5px 15px;
            border-radius: 0;
            font-size: 100%;
            font-weight: 700;
            text-transform: none;
            outline: none;
            -webkit-outline: none;
            -o-outline: none;
            -moz-outline: none;


            @media (max-width: 419px) {
                display: block;
                margin-bottom: 6px;
            }
        }
    }
}

.btn-twitter     {
    background: $white; //$primary; // #00ACEE;
    border: 1px solid $primary;
}
.btn-facebook    {
    background: $white; //$primary; // #3B5998;
    border: 1px solid $primary;
}
.btn-googleplus  {
    background: $white; //$primary; // #E93F2E;
    border: 1px solid $primary;
}
.btn-stumbleupon {
    background: $white; //$primary; // #F74425;
    border: 1px solid $primary;
}
.btn-linkedin    {
    background: $white; //$primary; // #0E76A8;
    border: 1px solid $primary;
}

.btn-twitter    ,
.btn-facebook   ,
.btn-googleplus ,
.btn-stumbleupon,
.btn-linkedin   , {
    color: $primary;
}

.btn-twitter:active    , .btn-twitter:hover     {
    color: lighten($primary, 10%);
    border: 1px solid lighten($primary, 10%);
    // background: lighten($primary, 5%); //#0087BD;
}
.btn-facebook:active   , .btn-facebook:hover    {
    color: lighten($primary, 10%);
    border: 1px solid lighten($primary, 10%);
    // background: lighten($primary, 5%); //#30477A;
}
.btn-googleplus:active , .btn-googleplus:hover  {
    color: lighten($primary, 10%);
    border: 1px solid lighten($primary, 10%);
    // background: lighten($primary, 5%); //#BA3225;
}
.btn-stumbleupon:active, .btn-stumbleupon:hover {
    color: lighten($primary, 10%);
    border: 1px solid lighten($primary, 10%);
    // background: lighten($primary, 5%); //#C7371E;
}
.btn-linkedin:active   , .btn-linkedin:hover    {
    color: lighten($primary, 10%);
    border: 1px solid lighten($primary, 10%);
    // background: lighten($primary, 5%); //#0B6087;
}
